import { FlowFlow } from './data-contracts';
import { HttpClient, RequestParams, TraceDataType } from './http-client';

import { loggerCan } from '@/data/utils/loggerUtil';
import { logger } from '@/logging/logger';

export class Flow<SecurityDataType = unknown> {
	http: HttpClient<SecurityDataType>;

	constructor(http: HttpClient<SecurityDataType>) {
		this.http = http;
	}

	/**
	 * @description Gets the flow info by flow ID.
	 *
	 * @tags Flow
	 * @name FlowFindByFlowId
	 * @summary Get flow information by ID
	 * @request GET:/store/{storeId}/flow/{flowId}
	 * @secure
	 * @response `200` `FlowFlow` The requested completed successfully.
	 */
	flowFindByFlowId = (storeId: string, flowId: string, params: RequestParams = {}) => {
		const { _requestId: requestId, skipErrorLogging, ..._params } = params as any;
		let traceData: TraceDataType | undefined;

		if (
			loggerCan('trace') &&
			(!this.http.traceDetails || this.http.traceDetails['flowFindByFlowId'])
		) {
			traceData = {
				requestId,
				msg: 'API request',
				url: `${this.http.baseUrl}/store/${storeId}/flow/${flowId}`,
				params: _params,
				methodName: 'flowFindByFlowId',
			};
			logger.trace(traceData);
		}
		return this.http.request<FlowFlow, any>({
			path: `/store/${storeId}/flow/${flowId}`,
			method: 'GET',
			secure: true,
			format: params.format ?? 'json',
			storeId,
			traceData,
			skipErrorLogging,
			..._params,
		});
	};
}
