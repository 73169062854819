import { HttpClient, RequestParams, TraceDataType } from './http-client';

import { loggerCan } from '@/data/utils/loggerUtil';
import { logger } from '@/logging/logger';

export class LlpbatchmanagementById<SecurityDataType = unknown> {
	http: HttpClient<SecurityDataType>;

	constructor(http: HttpClient<SecurityDataType>) {
		this.http = http;
	}

	/**
 * @description get verson count by its Id
 *
 * @tags llpbatchmanagement-by-id
 * @name JjkproductLlpbatchmanagementDetail
 * @summary Get verson count by its Id
 * @request GET:/store/{storeId}/jjkproduct/llpbatchmanagement/{productId}
 * @secure
 * @response `200` `{
    versionType?: any,

}` The requested completed successfully.
 * @response `400` `void` Bad request. The request could not be understood by the server due to malformed syntax.
 * @response `500` `void` Internal server error. For details, see the server log files.
 */
	jjkproductLlpbatchmanagementDetail = (
		storeId: string,
		productId: string,
		params: RequestParams = {}
	) => {
		const { _requestId: requestId, skipErrorLogging, ..._params } = params as any;
		let traceData: TraceDataType | undefined;

		if (
			loggerCan('trace') &&
			(!this.http.traceDetails || this.http.traceDetails['jjkproductLlpbatchmanagementDetail'])
		) {
			traceData = {
				requestId,
				msg: 'API request',
				url: `${this.http.baseUrl}/store/${storeId}/jjkproduct/llpbatchmanagement/${productId}`,
				params: _params,
				methodName: 'jjkproductLlpbatchmanagementDetail',
			};
			logger.trace(traceData);
		}
		return this.http.request<
			{
				versionType?: any;
			},
			void
		>({
			path: `/store/${storeId}/jjkproduct/llpbatchmanagement/${productId}`,
			method: 'GET',
			secure: true,
			format: params.format ?? 'json',
			storeId,
			traceData,
			skipErrorLogging,
			..._params,
		});
	};
}
