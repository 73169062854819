import {
	AddressValidationPluginOutput,
	ComIbmCommerceRestMemberHandlerPersonContactHandlerUserIdentifier,
	PersonContact,
	PersonDeleteContact,
	PersonSingleContact,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams, TraceDataType } from './http-client';

import { loggerCan } from '@/data/utils/loggerUtil';
import { logger } from '@/logging/logger';

export class Contact<SecurityDataType = unknown> {
	http: HttpClient<SecurityDataType>;

	constructor(http: HttpClient<SecurityDataType>) {
		this.http = http;
	}

	/**
	 * @description Gets the contacts in a person's address book. If the addressType optional query parameter is passed in, the returned contacts are filtered by addressType.
	 *
	 * @tags Contact
	 * @name ContactGetAllPersonContact
	 * @summary Get contact from address book
	 * @request GET:/store/{storeId}/person/@self/contact
	 * @secure
	 * @response `200` `PersonContact` The requested completed successfully.
	 * @response `400` `void` Bad request. The request could not be understood by the server due to malformed syntax.
	 * @response `401` `void` Not authenticated. The user session is not valid.
	 * @response `403` `void` The user is not authorized to perform the specified request.
	 * @response `500` `void` Internal server error. For details, see the server log files.
	 */
	contactGetAllPersonContact = (
		storeId: string,
		query?: {
			/** The addressType to filter contacts by. All contacts will be returned if a valid value is not supplied for this parameter. */
			addressType?: 'Shipping' | 'Billing' | 'ShippingAndBilling';
			/** The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json. */
			responseFormat?: 'xml' | 'json';
		},
		params: RequestParams = {}
	) => {
		const { _requestId: requestId, skipErrorLogging, ..._params } = params as any;
		let traceData: TraceDataType | undefined;

		if (
			loggerCan('trace') &&
			(!this.http.traceDetails || this.http.traceDetails['contactGetAllPersonContact'])
		) {
			traceData = {
				requestId,
				msg: 'API request',
				url: `${this.http.baseUrl}/store/${storeId}/person/@self/contact`,
				params: _params,
				query: query,
				methodName: 'contactGetAllPersonContact',
			};
			logger.trace(traceData);
		}
		return this.http.request<PersonContact, void>({
			path: `/store/${storeId}/person/@self/contact`,
			method: 'GET',
			query: query,
			secure: true,
			format: params.format ?? 'json',
			storeId,
			traceData,
			skipErrorLogging,
			..._params,
		});
	};
	/**
	 * @description Creates a new contact in a person's address book post verification from AVS.
	 *
	 * @tags Contact
	 * @name ContactAddPersonContact
	 * @summary Creates AVS verified contact in address book
	 * @request POST:/store/{storeId}/person/@self/contact
	 * @secure
	 * @response `200` `AddressValidationPluginOutput` Successful operation
	 * @response `201` `ComIbmCommerceRestMemberHandlerPersonContactHandlerUserIdentifier` The requested resource has been created.
	 * @response `400` `void` Bad request. The request could not be understood by the server due to malformed syntax.
	 * @response `401` `void` Not authenticated. The user session is not valid.
	 * @response `403` `void` The user is not authorized to perform the specified request.
	 * @response `500` `void` Internal server error. For details, see the server log files.
	 */
	contactAddPersonContact = (
		storeId: string,
		query?: {
			/** This flag indicates whether a contact needs to be verified using AVS or not. If flag is 'true' then address verification is not performed. If 'false' verification is performed. The default value is 'false' */
			bypassAVS?: boolean;
			/** The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json. */
			responseFormat?: 'xml' | 'json';
		},
		data?: PersonSingleContact,
		params: RequestParams = {}
	) => {
		const { _requestId: requestId, skipErrorLogging, ..._params } = params as any;
		let traceData: TraceDataType | undefined;

		if (
			loggerCan('trace') &&
			(!this.http.traceDetails || this.http.traceDetails['contactAddPersonContact'])
		) {
			traceData = {
				requestId,
				msg: 'API request',
				url: `${this.http.baseUrl}/store/${storeId}/person/@self/contact`,
				params: _params,
				query: query,
				body: data,
				methodName: 'contactAddPersonContact',
			};
			logger.trace(traceData);
		}
		return this.http.request<
			| AddressValidationPluginOutput
			| ComIbmCommerceRestMemberHandlerPersonContactHandlerUserIdentifier,
			void
		>({
			path: `/store/${storeId}/person/@self/contact`,
			method: 'POST',
			query: query,
			body: data,
			secure: true,
			type: params.type ?? ContentType.Json,
			format: params.format ?? 'json',
			storeId,
			traceData,
			skipErrorLogging,
			..._params,
		});
	};
	/**
	 * @description Gets a person contact by address ID.
	 *
	 * @tags Contact
	 * @name ContactFindPersonContactById
	 * @summary Get contact by address ID
	 * @request GET:/store/{storeId}/person/@self/contact/byAddressId/{addressId}
	 * @secure
	 * @response `200` `PersonSingleContact` The requested completed successfully.
	 * @response `400` `void` Bad request. The request could not be understood by the server due to malformed syntax.
	 * @response `401` `void` Not authenticated. The user session is not valid.
	 * @response `403` `void` The user is not authorized to perform the specified request.
	 * @response `404` `void` The specified resource could not be found.
	 * @response `500` `void` Internal server error. For details, see the server log files.
	 */
	contactFindPersonContactById = (
		storeId: string,
		addressId: string,
		query?: {
			/** The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json. */
			responseFormat?: 'xml' | 'json';
		},
		params: RequestParams = {}
	) => {
		const { _requestId: requestId, skipErrorLogging, ..._params } = params as any;
		let traceData: TraceDataType | undefined;

		if (
			loggerCan('trace') &&
			(!this.http.traceDetails || this.http.traceDetails['contactFindPersonContactById'])
		) {
			traceData = {
				requestId,
				msg: 'API request',
				url: `${this.http.baseUrl}/store/${storeId}/person/@self/contact/byAddressId/${addressId}`,
				params: _params,
				query: query,
				methodName: 'contactFindPersonContactById',
			};
			logger.trace(traceData);
		}
		return this.http.request<PersonSingleContact, void>({
			path: `/store/${storeId}/person/@self/contact/byAddressId/${addressId}`,
			method: 'GET',
			query: query,
			secure: true,
			format: params.format ?? 'json',
			storeId,
			traceData,
			skipErrorLogging,
			..._params,
		});
	};
	/**
	 * @description Gets a person contact by nickname.
	 *
	 * @tags Contact
	 * @name ContactFindPersonContactByNickName
	 * @summary Get contact by nickname
	 * @request GET:/store/{storeId}/person/@self/contact/{nickName}
	 * @secure
	 * @response `200` `PersonSingleContact` The requested completed successfully.
	 * @response `400` `void` Bad request. The request could not be understood by the server due to malformed syntax.
	 * @response `401` `void` Not authenticated. The user session is not valid.
	 * @response `403` `void` The user is not authorized to perform the specified request.
	 * @response `404` `void` The specified resource could not be found.
	 * @response `500` `void` Internal server error. For details, see the server log files.
	 */
	contactFindPersonContactByNickName = (
		storeId: string,
		nickName: string,
		query?: {
			/** The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json. */
			responseFormat?: 'xml' | 'json';
		},
		params: RequestParams = {}
	) => {
		const { _requestId: requestId, skipErrorLogging, ..._params } = params as any;
		let traceData: TraceDataType | undefined;

		if (
			loggerCan('trace') &&
			(!this.http.traceDetails || this.http.traceDetails['contactFindPersonContactByNickName'])
		) {
			traceData = {
				requestId,
				msg: 'API request',
				url: `${this.http.baseUrl}/store/${storeId}/person/@self/contact/${nickName}`,
				params: _params,
				query: query,
				methodName: 'contactFindPersonContactByNickName',
			};
			logger.trace(traceData);
		}
		return this.http.request<PersonSingleContact, void>({
			path: `/store/${storeId}/person/@self/contact/${nickName}`,
			method: 'GET',
			query: query,
			secure: true,
			format: params.format ?? 'json',
			storeId,
			traceData,
			skipErrorLogging,
			..._params,
		});
	};
	/**
	 * @description Updates the contact in the address book identified by nickname post verification from AVS.
	 *
	 * @tags Contact
	 * @name ContactUpdatePersonContact
	 * @summary Updates AVS verified address by nickname
	 * @request PUT:/store/{storeId}/person/@self/contact/{nickName}
	 * @secure
	 * @response `200` `(AddressValidationPluginOutput | ComIbmCommerceRestMemberHandlerPersonContactHandlerUserIdentifier)` The request completed successfully.
	 * @response `400` `void` Bad request. The request could not be understood by the server due to malformed syntax.
	 * @response `401` `void` Not authenticated. The user session is not valid.
	 * @response `403` `void` The user is not authorized to perform the specified request.
	 * @response `404` `void` The specified resource could not be found.
	 * @response `500` `void` Internal server error. For details, see the server log files.
	 */
	contactUpdatePersonContact = (
		storeId: string,
		nickName: string,
		query?: {
			/** This flag indicates whether a contact needs to be verified using AVS or not. If flag is 'true' then address verification is not performed. If 'false' verification is performed. The default value is 'false' */
			bypassAVS?: boolean;
			/** The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json. */
			responseFormat?: 'xml' | 'json';
		},
		data?: PersonSingleContact,
		params: RequestParams = {}
	) => {
		const { _requestId: requestId, skipErrorLogging, ..._params } = params as any;
		let traceData: TraceDataType | undefined;

		if (
			loggerCan('trace') &&
			(!this.http.traceDetails || this.http.traceDetails['contactUpdatePersonContact'])
		) {
			traceData = {
				requestId,
				msg: 'API request',
				url: `${this.http.baseUrl}/store/${storeId}/person/@self/contact/${nickName}`,
				params: _params,
				query: query,
				body: data,
				methodName: 'contactUpdatePersonContact',
			};
			logger.trace(traceData);
		}
		return this.http.request<
			| AddressValidationPluginOutput
			| ComIbmCommerceRestMemberHandlerPersonContactHandlerUserIdentifier,
			void
		>({
			path: `/store/${storeId}/person/@self/contact/${nickName}`,
			method: 'PUT',
			query: query,
			body: data,
			secure: true,
			type: params.type ?? ContentType.Json,
			format: params.format ?? 'json',
			storeId,
			traceData,
			skipErrorLogging,
			..._params,
		});
	};
	/**
	 * @description Deletes the contact in the address book identified by nickname.
	 *
	 * @tags Contact
	 * @name ContactDeletePersonContact
	 * @summary Delete contact from address book
	 * @request DELETE:/store/{storeId}/person/@self/contact/{nickName}
	 * @secure
	 * @response `200` `PersonDeleteContact` The requested completed successfully.
	 * @response `400` `void` Bad request. The request could not be understood by the server due to malformed syntax.
	 * @response `401` `void` Not authenticated. The user session is not valid.
	 * @response `403` `void` The user is not authorized to perform the specified request.
	 * @response `404` `void` The specified resource could not be found.
	 * @response `500` `void` Internal server error. For details, see the server log files.
	 */
	contactDeletePersonContact = (
		storeId: string,
		nickName: string,
		query?: {
			/** The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json. */
			responseFormat?: 'xml' | 'json';
		},
		params: RequestParams = {}
	) => {
		const { _requestId: requestId, skipErrorLogging, ..._params } = params as any;
		let traceData: TraceDataType | undefined;

		if (
			loggerCan('trace') &&
			(!this.http.traceDetails || this.http.traceDetails['contactDeletePersonContact'])
		) {
			traceData = {
				requestId,
				msg: 'API request',
				url: `${this.http.baseUrl}/store/${storeId}/person/@self/contact/${nickName}`,
				params: _params,
				query: query,
				methodName: 'contactDeletePersonContact',
			};
			logger.trace(traceData);
		}
		return this.http.request<PersonDeleteContact, void>({
			path: `/store/${storeId}/person/@self/contact/${nickName}`,
			method: 'DELETE',
			query: query,
			secure: true,
			format: params.format ?? 'json',
			storeId,
			traceData,
			skipErrorLogging,
			..._params,
		});
	};
}
