"use strict";
/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_CENSOR_STRING = exports.DEFAULT_LOG_LEVEL = exports.TIME_FORMAT = exports.DEVELOPMENT = void 0;
exports.DEVELOPMENT = 'development';
exports.TIME_FORMAT = 'SYS:yyyy-mm-dd HH:MM:ss';
exports.DEFAULT_LOG_LEVEL = 'info';
exports.DEFAULT_CENSOR_STRING = '****';
