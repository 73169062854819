import { ComIbmCommerceEmailsubscription } from './data-contracts';
import { ContentType, HttpClient, RequestParams, TraceDataType } from './http-client';

import { loggerCan } from '@/data/utils/loggerUtil';
import { logger } from '@/logging/logger';

export class SubmitEmail<SecurityDataType = unknown> {
	http: HttpClient<SecurityDataType>;

	constructor(http: HttpClient<SecurityDataType>) {
		this.http = http;
	}

	/**
 * @description submit email
 *
 * @tags submit-email
 * @name JjkEmailGetEmailSubscriptionCreate
 * @summary submit email
 * @request POST:/store/{storeId}/jjkEmail/getEmailSubscription
 * @secure
 * @response `200` `{
    emailAddress?: string,
    status?: string,

}` The requested completed successfully.
 * @response `400` `void` Bad request. The request could not be understood by the server due to malformed syntax.
 * @response `500` `void` Internal server error. For details, see the server log files.
 */
	jjkEmailGetEmailSubscriptionCreate = (
		storeId: string,
		data: ComIbmCommerceEmailsubscription,
		params: RequestParams = {}
	) => {
		const { _requestId: requestId, skipErrorLogging, ..._params } = params as any;
		let traceData: TraceDataType | undefined;

		if (
			loggerCan('trace') &&
			(!this.http.traceDetails || this.http.traceDetails['jjkEmailGetEmailSubscriptionCreate'])
		) {
			traceData = {
				requestId,
				msg: 'API request',
				url: `${this.http.baseUrl}/store/${storeId}/jjkEmail/getEmailSubscription`,
				params: _params,
				body: data,
				methodName: 'jjkEmailGetEmailSubscriptionCreate',
			};
			logger.trace(traceData);
		}
		return this.http.request<
			{
				emailAddress?: string;
				status?: string;
			},
			void
		>({
			path: `/store/${storeId}/jjkEmail/getEmailSubscription`,
			method: 'POST',
			body: data,
			secure: true,
			type: params.type ?? ContentType.Json,
			format: params.format ?? 'json',
			storeId,
			traceData,
			skipErrorLogging,
			..._params,
		});
	};
}
