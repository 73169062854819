/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

export const KIT_TABLE_PREFIX = 'kit-table';
export const BUNDLE_TABLE_PREFIX = 'bundle-table';
export const TYPES = {
	bundle: 'bundle',
	kit: 'package',
	product: 'product',
	sku: 'item',
	variant: 'variant',
};

export const KIT_TABLE_ACCESSOR_KEYS = {
	quantity: 'quantity',
	itemDetails: 'itemDetails',
	expanderOrAttributes: 'attributes',
};

export const SKU_LIST_TABLE_PREFIX = 'sku-list-table';
export const SKU_LIST_TABLE_ACCESSOR_KEYS = {
	attributes: 'attributes',
	partNumber: 'partNumber',
	price: 'price',
	quantity: 'quantity',
	availability: 'availability',
	pickup: 'pickup',
};
export const PRODUCT_INFO_STATE_KEY = 'productInfoData';
export const SKU_LIST_TABLE_MAX_ATTRIBUTE_HEADER_SIZE = 2;
export const SKU_LIST_TABLE_MAX_ATTRIBUTE_HEADER_SIZE_ON_DIALOG = 2;

export const ADD_TO_LISTS_DISPLAY_TIMEOUT = 300;

export const S3_IMAGE_PATH_FOR_CATEGORY_AND_PDP = 'https://cdn.jjkeller.com';

export const CURRENT_POSTER_VALUE = 'C';
export const UPDATED_POSTER_VALUE = 'F';

export const PRODUCT_PRODUCTTYPE_VALUE = 'product_productType_value';
export const PRODUCT_PRODUCTTYPE_FEDEX_CONTENT = 'product_productType_fedex_content';
export const FEDEXTRAININGEVENT = 'FEDEXTRAININGEVENT';
export const FEDEX_PDP_ESPOT_CONTENT = 'FedEx_PDP_Espot';
export const IBM_STORE_ENTITLED_PRICE_RANGE_PRICE_ALL = 'IBM_Store_EntitledPrice_RangePrice_All';

export const CUSTOM_FIELD5_VALUES = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i'];

// export const CUSTOM_SIGNS_HOSTNAME = 'https://jjk.csp-1.com';
export const CUSTOM_SIGNS_HOSTNAME = 'https://app-csp-demo.azurewebsites.net/';
export const ZOOM_IMAGE_USAGE = 'IMAGE_SIZE_1000';

export const IMPRINT_SEAL_LOGO_TYPE = [
	'image/jpeg',
	'image/tiff',
	'image/vnd.adobe.photoshop',
	'application/pdf',
];
export const IMPRINT_SEAL_LOGO_EXTENSION = ['.jpg', '.tiff', '.psd', '.pdf'];
export const IMPRINT_SEAL_NUMBER_ERROR_KEY = 'ERR_STARTING_NUMBER_VALIDATION';
export const IMPRINT_SEAL_SAP_ERROR_KEY = 'ERR_SAP_SERVICE_FAILURE';
export const _ERR_GENERIC_KEY = '_ERR_GENERIC';

export const ZOOVU_API = 'https://api-tiger.zoovu.com/api/v1/advisors/$id/js-loader?locale=en-US';
export const ZOOVU_SECURITY_SEAL_ID = 'PGMZdvtL';
export const ZOOVU_PLACARD_ID = 'vD5FkSk2';
