/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

// TODO: remove this post 9.1.14.0
/**
 * @deprecated Global state `selectedProfile` is deprecated in 9.1.14.0, will be removed in future release.
 */
export const SELECTED_PROFILE = 'selectedProfile';
