"use strict";
/*
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited 2023.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.errorWithId = exports.debugWithId = exports.traceWithId = exports.infoWithId = exports.error = exports.debug = exports.trace = exports.loggerCan = void 0;
const logger_1 = require("../../../../logging/core/logger");
const loggerCan = (level) => logger_1.logger.levelVal <= logger_1.logger.levels.values[level];
exports.loggerCan = loggerCan;
/**
 * @deprecated use traceWithId
 */
const trace = (req, msg, ...params) => {
    var _a;
    if ((0, exports.loggerCan)('trace')) {
        const id = (_a = req === null || req === void 0 ? void 0 : req.id) !== null && _a !== void 0 ? _a : 'client';
        logger_1.logger.trace(`Request: %o: ${msg}`, id, ...params);
    }
};
exports.trace = trace;
/**
 * @deprecated use debugWithId
 */
const debug = (req, msg, ...params) => {
    var _a;
    if ((0, exports.loggerCan)('debug')) {
        const id = (_a = req === null || req === void 0 ? void 0 : req.id) !== null && _a !== void 0 ? _a : 'client';
        logger_1.logger.debug(`Request: %o: ${msg}`, id, ...params);
    }
};
exports.debug = debug;
/**
 * @deprecated use errorWithId
 */
const error = (req, msg, ...params) => {
    var _a;
    if ((0, exports.loggerCan)('error')) {
        const id = (_a = req === null || req === void 0 ? void 0 : req.id) !== null && _a !== void 0 ? _a : 'client';
        logger_1.logger.error(`Request: %o: ${msg}`, id, ...params);
    }
};
exports.error = error;
const getLog = (requestId, msg, objects) => ({
    requestId,
    msg,
    ...objects,
});
const infoWithId = (requestId, msg, objects) => {
    if ((0, exports.loggerCan)('info')) {
        logger_1.logger.info(getLog(requestId, msg, objects));
    }
};
exports.infoWithId = infoWithId;
const traceWithId = (requestId, msg, objects) => {
    if ((0, exports.loggerCan)('trace')) {
        logger_1.logger.trace(getLog(requestId, msg, objects));
    }
};
exports.traceWithId = traceWithId;
const debugWithId = (requestId, msg, objects) => {
    if ((0, exports.loggerCan)('debug')) {
        logger_1.logger.debug(getLog(requestId, msg, objects));
    }
};
exports.debugWithId = debugWithId;
const errorWithId = (requestId, msg, objects) => {
    if ((0, exports.loggerCan)('error')) {
        logger_1.logger.error(getLog(requestId, msg, objects));
    }
};
exports.errorWithId = errorWithId;
