import { ComIbmCommerceCoBrandCategoryLabelsResponse } from './data-contracts';
import { HttpClient, RequestParams, TraceDataType } from './http-client';

import { loggerCan } from '@/data/utils/loggerUtil';
import { logger } from '@/logging/logger';

export class CoBrandCategory<SecurityDataType = unknown> {
	http: HttpClient<SecurityDataType>;

	constructor(http: HttpClient<SecurityDataType>) {
		this.http = http;
	}

	/**
	 * @description get co brand category
	 *
	 * @tags co-brand-category
	 * @name GetCobrandCategory
	 * @summary get co-brand category
	 * @request GET:/store/{storeId}/co-branded/{category}
	 * @secure
	 * @response `200` `ComIbmCommerceCoBrandCategoryLabelsResponse` The requested completed successfully.
	 * @response `400` `void` Bad request. The request could not be understood by the server due to malformed syntax.
	 * @response `500` `void` Internal server error. For details, see the server log files.
	 */
	getCobrandCategory = (storeId: string, category: string, params: RequestParams = {}) => {
		const { _requestId: requestId, skipErrorLogging, ..._params } = params as any;
		let traceData: TraceDataType | undefined;

		if (
			loggerCan('trace') &&
			(!this.http.traceDetails || this.http.traceDetails['getCobrandCategory'])
		) {
			traceData = {
				requestId,
				msg: 'API request',
				url: `${this.http.baseUrl}/store/${storeId}/co-branded/${category}`,
				params: _params,
				methodName: 'getCobrandCategory',
			};
			logger.trace(traceData);
		}
		return this.http.request<ComIbmCommerceCoBrandCategoryLabelsResponse, void>({
			path: `/store/${storeId}/co-branded/${category}`,
			method: 'GET',
			secure: true,
			format: params.format ?? 'json',
			storeId,
			traceData,
			skipErrorLogging,
			..._params,
		});
	};
}
