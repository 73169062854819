import {
	ComIbmCommerceRestSchedulerHandlerJobHandlerCreateJobRequest,
	ComIbmCommerceRestSchedulerHandlerJobHandlerCreateJobResponse,
	ComIbmCommerceSchedulerBeansJobDataBeanIBMAdminSummary,
	ComIbmCommerceSchedulerBeansJobStatusListDataBeanIBMAdminSummary,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams, TraceDataType } from './http-client';

import { loggerCan } from '@/data/utils/loggerUtil';
import { logger } from '@/logging/logger';

export class Job<SecurityDataType = unknown> {
	http: HttpClient<SecurityDataType>;

	constructor(http: HttpClient<SecurityDataType>) {
		this.http = http;
	}

	/**
	 * No description
	 *
	 * @tags job
	 * @name PostJob
	 * @summary createJob.
	 * @request POST:/store/{storeId}/job
	 * @secure
	 * @response `200` `ComIbmCommerceRestSchedulerHandlerJobHandlerCreateJobResponse` The requested completed successfully.
	 * @response `400` `void` Bad request. The request could not be understood by the server due to malformed syntax.
	 * @response `401` `void` Not authenticated. The user session is not valid.
	 * @response `403` `void` The user is not authorized to perform the specified request.
	 * @response `404` `void` The specified resource could not be found.
	 * @response `500` `void` Internal server error. For details, see the server log files.
	 */
	postJob = (
		storeId: string,
		data?: ComIbmCommerceRestSchedulerHandlerJobHandlerCreateJobRequest,
		params: RequestParams = {}
	) => {
		const { _requestId: requestId, skipErrorLogging, ..._params } = params as any;
		let traceData: TraceDataType | undefined;

		if (loggerCan('trace') && (!this.http.traceDetails || this.http.traceDetails['postJob'])) {
			traceData = {
				requestId,
				msg: 'API request',
				url: `${this.http.baseUrl}/store/${storeId}/job`,
				params: _params,
				body: data,
				methodName: 'postJob',
			};
			logger.trace(traceData);
		}
		return this.http.request<ComIbmCommerceRestSchedulerHandlerJobHandlerCreateJobResponse, void>({
			path: `/store/${storeId}/job`,
			method: 'POST',
			body: data,
			secure: true,
			type: params.type ?? ContentType.Json,
			format: params.format ?? 'json',
			storeId,
			traceData,
			skipErrorLogging,
			..._params,
		});
	};
	/**
	 * No description
	 *
	 * @tags job
	 * @name GetJob
	 * @summary findJobById.
	 * @request GET:/store/{storeId}/job/{jobId}
	 * @secure
	 * @response `200` `ComIbmCommerceSchedulerBeansJobDataBeanIBMAdminSummary` The requested completed successfully.
	 * @response `400` `void` Bad request. The request could not be understood by the server due to malformed syntax.
	 * @response `401` `void` Not authenticated. The user session is not valid.
	 * @response `403` `void` The user is not authorized to perform the specified request.
	 * @response `404` `void` The specified resource could not be found.
	 * @response `500` `void` Internal server error. For details, see the server log files.
	 */
	getJob = (storeId: string, jobId: string, params: RequestParams = {}) => {
		const { _requestId: requestId, skipErrorLogging, ..._params } = params as any;
		let traceData: TraceDataType | undefined;

		if (loggerCan('trace') && (!this.http.traceDetails || this.http.traceDetails['getJob'])) {
			traceData = {
				requestId,
				msg: 'API request',
				url: `${this.http.baseUrl}/store/${storeId}/job/${jobId}`,
				params: _params,
				methodName: 'getJob',
			};
			logger.trace(traceData);
		}
		return this.http.request<ComIbmCommerceSchedulerBeansJobDataBeanIBMAdminSummary, void>({
			path: `/store/${storeId}/job/${jobId}`,
			method: 'GET',
			secure: true,
			format: params.format ?? 'json',
			storeId,
			traceData,
			skipErrorLogging,
			..._params,
		});
	};
	/**
	 * No description
	 *
	 * @tags job
	 * @name JobStatusDetail
	 * @summary findJobStatuses.
	 * @request GET:/store/{storeId}/job_status
	 * @secure
	 * @response `200` `ComIbmCommerceSchedulerBeansJobStatusListDataBeanIBMAdminSummary` The requested completed successfully.
	 * @response `400` `void` Bad request. The request could not be understood by the server due to malformed syntax.
	 * @response `401` `void` Not authenticated. The user session is not valid.
	 * @response `403` `void` The user is not authorized to perform the specified request.
	 * @response `404` `void` The specified resource could not be found.
	 * @response `500` `void` Internal server error. For details, see the server log files.
	 */
	jobStatusDetail = (
		storeId: string,
		query?: {
			/** jobId */
			jobId?: string;
		},
		params: RequestParams = {}
	) => {
		const { _requestId: requestId, skipErrorLogging, ..._params } = params as any;
		let traceData: TraceDataType | undefined;

		if (
			loggerCan('trace') &&
			(!this.http.traceDetails || this.http.traceDetails['jobStatusDetail'])
		) {
			traceData = {
				requestId,
				msg: 'API request',
				url: `${this.http.baseUrl}/store/${storeId}/job_status`,
				params: _params,
				query: query,
				methodName: 'jobStatusDetail',
			};
			logger.trace(traceData);
		}
		return this.http.request<
			ComIbmCommerceSchedulerBeansJobStatusListDataBeanIBMAdminSummary,
			void
		>({
			path: `/store/${storeId}/job_status`,
			method: 'GET',
			query: query,
			secure: true,
			format: params.format ?? 'json',
			storeId,
			traceData,
			skipErrorLogging,
			..._params,
		});
	};
}
