import {
	ComIbmCommerceContractBeansExtendedTermConditionDataBeanIBMStoreDetails,
	ComIbmCommerceToolsContractBeansDisplayCustomizationTCDataBeanIBMStoreDetails,
} from './data-contracts';
import { HttpClient, RequestParams, TraceDataType } from './http-client';

import { loggerCan } from '@/data/utils/loggerUtil';
import { logger } from '@/logging/logger';

export class TermCondition<SecurityDataType = unknown> {
	http: HttpClient<SecurityDataType>;

	constructor(http: HttpClient<SecurityDataType>) {
		this.http = http;
	}

	/**
	 * No description
	 *
	 * @tags term_condition
	 * @name TermConditionDetail
	 * @summary Finds the display customization TC of the account associated with the current user.
	 * @request GET:/store/{storeId}/term_condition
	 * @secure
	 * @response `200` `ComIbmCommerceToolsContractBeansDisplayCustomizationTCDataBeanIBMStoreDetails` The requested completed successfully.
	 * @response `400` `void` Bad request. The request could not be understood by the server due to malformed syntax.
	 * @response `401` `void` Not authenticated. The user session is not valid.
	 * @response `403` `void` The user is not authorized to perform the specified request.
	 * @response `404` `void` The specified resource could not be found.
	 * @response `500` `void` Internal server error. For details, see the server log files.
	 */
	termConditionDetail = (
		storeId: string,
		query: {
			/** The query name. Possible value is displayCustomizationTC. */
			q: string;
		},
		params: RequestParams = {}
	) => {
		const { _requestId: requestId, skipErrorLogging, ..._params } = params as any;
		let traceData: TraceDataType | undefined;

		if (
			loggerCan('trace') &&
			(!this.http.traceDetails || this.http.traceDetails['termConditionDetail'])
		) {
			traceData = {
				requestId,
				msg: 'API request',
				url: `${this.http.baseUrl}/store/${storeId}/term_condition`,
				params: _params,
				query: query,
				methodName: 'termConditionDetail',
			};
			logger.trace(traceData);
		}
		return this.http.request<
			ComIbmCommerceToolsContractBeansDisplayCustomizationTCDataBeanIBMStoreDetails,
			void
		>({
			path: `/store/${storeId}/term_condition`,
			method: 'GET',
			query: query,
			secure: true,
			format: params.format ?? 'json',
			storeId,
			traceData,
			skipErrorLogging,
			..._params,
		});
	};
	/**
	 * No description
	 *
	 * @tags term_condition
	 * @name TermConditionDetail2
	 * @summary Finds a term condition by its ID.
	 * @request GET:/store/{storeId}/term_condition/{termConditionId}
	 * @originalName termConditionDetail
	 * @duplicate
	 * @secure
	 * @response `200` `ComIbmCommerceContractBeansExtendedTermConditionDataBeanIBMStoreDetails` The requested completed successfully.
	 * @response `400` `void` Bad request. The request could not be understood by the server due to malformed syntax.
	 * @response `401` `void` Not authenticated. The user session is not valid.
	 * @response `403` `void` The user is not authorized to perform the specified request.
	 * @response `404` `void` The specified resource could not be found.
	 * @response `500` `void` Internal server error. For details, see the server log files.
	 */
	termConditionDetail2 = (storeId: string, termConditionId: string, params: RequestParams = {}) => {
		const { _requestId: requestId, skipErrorLogging, ..._params } = params as any;
		let traceData: TraceDataType | undefined;

		if (
			loggerCan('trace') &&
			(!this.http.traceDetails || this.http.traceDetails['termConditionDetail2'])
		) {
			traceData = {
				requestId,
				msg: 'API request',
				url: `${this.http.baseUrl}/store/${storeId}/term_condition/${termConditionId}`,
				params: _params,
				methodName: 'termConditionDetail2',
			};
			logger.trace(traceData);
		}
		return this.http.request<
			ComIbmCommerceContractBeansExtendedTermConditionDataBeanIBMStoreDetails,
			void
		>({
			path: `/store/${storeId}/term_condition/${termConditionId}`,
			method: 'GET',
			secure: true,
			format: params.format ?? 'json',
			storeId,
			traceData,
			skipErrorLogging,
			..._params,
		});
	};
}
